import downloadSvg from "assets/img/download.svg";
import loadingSvg from "assets/img/loading.svg";
import Button from "components/Button";
import ButtonWithConfirmation, { DialogIcon } from "components/ButtonWithConfirmation";
import { Card, CardList } from "components/Card";
import Dropdown from "components/Dropdown";
import FileTypeFilter, { filterFilesByType } from "components/FileTypeFilter";
import Form from "components/Form";
import Icon from "components/Icon";
import Page from "components/Page";
import { Pill, PillType, getPillProps } from "components/Pill";
import Table from "components/Table";
import { Tooltip } from "components/Tooltip";
import { RouteProps } from "containers/App";
import ProjectFilter, { FilterOptions } from "containers/ProjectFilter";
import Constants from "helpers/constants";
import { File } from "interfaces/file";
import { FetchAllStoresOrFilesQueryOptions } from "interfaces/filterQuery";
import { ProjectType } from "interfaces/project";
import { ReactSelectObject } from "interfaces/select";
import moment from "moment";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { State } from "state";
import {
  DownloadSearchFields,
  EventTypes,
  FileStatus,
  FileType,
  createAnalyticsEvent,
} from "state/analytics";
import { fetchAllFiles, initAllFiles } from "state/files";
import { fetchZipForFilesDownload } from "../state/batch_download";

interface DownloadsState {
  tableData: File[];
  currentId: number;
  currentLabel: string;
  currentType: string;
  selectedFileTypes: ReactSelectObject[];
  selectedAttachment: ReactSelectObject;
  query: FetchAllStoresOrFilesQueryOptions;
  fields: Set<string>;
  downloadMessage?: string;
  disableRepeatDownload?: boolean;
}

type DownloadsProps = PropsFromRedux & RouteProps;

const fileTypeOptions = [
  { label: "PDF", value: "pdf", "data-qa": "pdf" },
  { label: "PSA", value: "psa", "data-qa": "psa" },
];

const STORE_ATTACHMENTS = "Store Attachments";
const PROJ_ATTACHMENTS = "Project Attachments";
const ALL_ATTACHMENTS = "All Attachments";

const attachmentOptions = [
  { label: ALL_ATTACHMENTS, value: "All" },
  { label: STORE_ATTACHMENTS, value: "Store", "data-qa": "store-attachments" },
  {
    label: PROJ_ATTACHMENTS,
    value: "Project",
    "data-qa": "project-attachments",
  },
];

const statusArray = [
  "Store Status",
  Constants.RESET_NOT_SCHEDULED,
  Constants.RESET_SCHEDULED,
  Constants.RESET_COMPLETE,
  Constants.NEEDS_VALIDATION,
  Constants.VALIDATION_PENDING,
  Constants.FEEDBACK_UNDER_REVIEW,
  Constants.NEEDS_FEEDBACK_REVIEW,
  Constants.AWAITING_FINAL_UPLOAD,
  Constants.VALIDATION_COMPLETE,
];

const userDropdownOptions = statusArray.map((arrVal, i) => ({
  label: arrVal,
  value: i === 0 ? "" : arrVal,
  "data-qa": arrVal,
}));

const defaultQuery: FetchAllStoresOrFilesQueryOptions = {
  storeName: "",
  storeNumber: "",
  status:"",
  address: "",
  city: "",
  state: "",
  zip: "",
  abWholesaler: "",
  millerWholesaler: "",
  coorsWholesaler: "",
  thirdPartyWholesaler: "",
  advertisingZone: "",
  cluster: "",
};

const defaultState: DownloadsState = {
  tableData: [],
  currentId: -999,
  currentLabel: "",
  currentType: "",
  selectedFileTypes: [],
  selectedAttachment: attachmentOptions[0],
  query: defaultQuery,
  fields: new Set(),
};

let showStoreColumns = true;

class Downloads extends React.Component<DownloadsProps, DownloadsState> {
  public state = defaultState;

  public componentDidMount() {
    this.props.initAllFiles();
  }

  public emailCount() {
    const fileCount = this.props.files ? this.props.files.length : 0;
    return Math.ceil(fileCount / 250);
  }

  public handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    const {
      currentId,
      currentLabel,
      currentType,
      fields,
      selectedAttachment,
      selectedFileTypes,
      query,
    } = this.state;
    this.props.fetchAllFiles(currentId, query).then(() => {
      const { files = [] } = this.props;
      showStoreColumns =
        this.state.selectedAttachment.label === STORE_ATTACHMENTS ||
        this.state.selectedAttachment.label === ALL_ATTACHMENTS;
      this.setState({
        tableData: filterFilesByType(
          this.state.selectedFileTypes,
          files.filter(
            (file: File) =>
              file.maptype.toUpperCase() === this.state.selectedAttachment.value.toUpperCase() ||
              this.state.selectedAttachment.label === ALL_ATTACHMENTS
          )
        ),
        disableRepeatDownload: false,
        downloadMessage: "",
      });

      this.props.createAnalyticsEvent({
        event: EventTypes.DOWNLOADS_SEARCH,
        eventType: "Action",
        projectName: currentLabel,
        projectType: currentType as ProjectType,
        searchResults: files.length,
        searchFieldsList: [
          ...Array.from(fields),
          `Attachment Type - ${selectedAttachment.value}`,
          ...selectedFileTypes.map((ft) => `File Type - ${ft.value.toLocaleUpperCase()}`),
        ] as DownloadSearchFields[],
        searchAction: "Search",
      });
    });
  };

  public handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    // reset form and inputs
    this.setState({
      query: defaultQuery,
      selectedAttachment: attachmentOptions[0],
      selectedFileTypes: [],
      fields: new Set(),
    });

    this.props.createAnalyticsEvent({
      event: EventTypes.DOWNLOADS_SEARCH,
      eventType: "Action",
      searchAction: "Clear",
    });
  };

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      query: {
        ...this.state.query,
        [e.currentTarget.name]: e.currentTarget.value,
      },
      fields: this.state.fields.add(e.currentTarget.placeholder),
    });
  };

  public handleChangeStatus = (newValue: string) => {
    this.setState({
      query: {
        ...this.state.query,
        status: newValue,
      },
      fields: this.state.fields.add("Status"),
    });
  };

  public handleBatchDownload = (e: React.MouseEvent<HTMLFormElement>) => {
    const { currentLabel, currentType } = this.state;

    const fileList: Array<Partial<File>> = this.state.tableData.map((file) => {
      return {
        status: file.status,
        blob_url: file.blob_url,
        filename: file.name,
      };
    });

    this.props.createAnalyticsEvent({
      event: EventTypes.DOWNLOAD_FILES,
      eventType: "Action",
      projectName: currentLabel,
      projectType: currentType as ProjectType,
      downloadFileCount: fileList.length,
    });

    const [chain, project] = currentLabel.split(/-/).map((l) => l.trim());
    this.props
      .fetchZipForFilesDownload(project, chain, fileList)
      .then(() => {
        if (this.props.hasError) {
          return;
        }
        this.setState({
          ...this.state,
          downloadMessage: Constants.DOWNLOAD_FILES_SUCCESS_MESSAGE,
          disableRepeatDownload: true,
        });
      })
      .catch(() =>
        this.setState({
          ...this.state,
          downloadMessage: Constants.DOWNLOAD_FILES_ERROR_MESSAGE,
        })
      );
  };

  public handleDownloadClick = (e: React.MouseEvent<HTMLButtonElement>, file: File) => {
    const { name, maptype, status, blob_url: blobUrl } = file;
    const { currentLabel, currentType } = this.state;

    this.props.createAnalyticsEvent({
      event: EventTypes.DOWNLOAD_FILES,
      eventType: "Action",
      projectName: currentLabel,
      projectType: currentType as ProjectType,
      downloadFileCount: 1,
      downloadFileType: maptype as FileType,
      downloadFileName: name,
      downloadFileStatus: status as FileStatus,
    });

    return window.open(blobUrl);
  };

  public renderDownloadStatus() {
    if (this.props.isLoadingDownloads) {
      return <img className="spin-right" src={loadingSvg} alt="Loading Icon" />;
    } else if (this.props.hasError) {
      return (
        <span className="download-error error">
          {Constants.DOWNLOAD_FILES_DOWNLOAD_FAILED_MESSAGE}
        </span>
      );
    } else if (this.state.downloadMessage) {
      return <span className="download-message">{this.state.downloadMessage}</span>;
    }
    return;
  }

  public renderForm() {
    const { currentId } = this.state;
    const buttonDisabled = currentId < 0;
    const disabled = buttonDisabled || this.state.selectedAttachment.label === PROJ_ATTACHMENTS;
    const colorPicker: string =
      this.state.query.status && this.state.query.status !== "Store Status" ? "#4a4a4a" : "#acacac";

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group title="Store Details" htmlFor="storeDetails">
          <Form.Input
            type="text"
            placeholder="Store Number"
            name="storeNumber"
            value={this.state.query.storeNumber}
            onChange={this.handleChange}
            disabled={disabled}
            data-qa="store-number-field"
          />
          <Form.Input
            type="text"
            placeholder="Store Name"
            disabled={disabled}
            data-qa="store-name-field"
            value={this.state.query.storeName}
            name="storeName"
            onChange={this.handleChange}
          />
          <div data-qa="status-dropdown">
            <Dropdown
              value={this.state.query.status}
              options={userDropdownOptions}
              placeholder={this.state.query.status ? this.state.query.status : "Store Status"}
              isDisabled={false}
              containerStyles={{
                marginLeft: "unset",
                marginTop: "0px",
                marginBottom: "20px",
                border: "1px solid #8d8d92",
                fontSize: "13px",
                color: "red",
                boxShadow: "noShadow",
              }}
              controlStyles={{}}
              placeholderStyles={{
                color: colorPicker,
              }}
              onChange={(status) => {
                this.handleChangeStatus(status.value);
              }}
            />
          </div>
        </Form.Group>

        <Form.Group title="Address">
          <Form.Input
            type="text"
            placeholder="Address"
            disabled={disabled}
            data-qa="address-field"
            value={this.state.query.address}
            name="address"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="City"
            disabled={disabled}
            data-qa="city-field"
            value={this.state.query.city}
            name="city"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="State"
            disabled={disabled}
            data-qa="state-field"
            value={this.state.query.state}
            name="state"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="Zip Code"
            disabled={disabled}
            data-qa="zip-code-field"
            value={this.state.query.zip}
            name="zip"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group title="Additional Information">
          <Form.Input
            type="text"
            placeholder="AB InBev Wholesaler"
            disabled={disabled}
            data-qa="ab-inbev-wholesaler-field"
            value={this.state.query.abWholesaler}
            name="abWholesaler"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="Miller Wholesaler"
            disabled={disabled}
            data-qa="miller-wholesaler-field"
            value={this.state.query.millerWholesaler}
            name="millerWholesaler"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="Coors Wholesaler"
            disabled={disabled}
            data-qa="coors-wholesaler-field"
            value={this.state.query.coorsWholesaler}
            name="coorsWholesaler"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="3rd Party Wholesaler"
            disabled={disabled}
            data-qa="3rd-party-wholesaler-field"
            value={this.state.query.thirdPartyWholesaler}
            name="thirdPartyWholesaler"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="AdZone"
            disabled={disabled}
            data-qa="adzone-field"
            value={this.state.query.advertisingZone}
            name="advertisingZone"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="Cluster/Store Group"
            disabled={disabled}
            data-qa="group-field"
            value={this.state.query.cluster}
            name="cluster"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.ButtonGroup>
          <Form.CancelButton
            onClick={this.handleClear}
            disabled={buttonDisabled && this.state.selectedFileTypes.length === 0}
            data-qa="clear-button"
          >
            Clear
          </Form.CancelButton>
          <Form.ConfirmButton disabled={buttonDisabled} data-qa="search-button">
            Search
          </Form.ConfirmButton>
        </Form.ButtonGroup>
      </Form>
    );
  }

  public render() {
    const { disableRepeatDownload } = this.state;

    return (
      <Page>
        <h1>Downloads</h1>
        <CardList>
          <Card>
            <Card.Header>
              <div className="downloads-header">
                <div data-qa="select-project">
                  <ProjectFilter
                    onChange={({ id, name, type }: FilterOptions) => {
                      this.setState({
                        currentId: id,
                        currentLabel: name,
                        currentType: type,
                      });
                    }}
                    isSearchable
                  />
                </div>
                <div data-qa="select-file-type">
                  <FileTypeFilter
                    fileTypeOptions={fileTypeOptions}
                    onChange={(selectedFileTypes: ReactSelectObject[]) => {
                      this.setState({ selectedFileTypes });
                    }}
                    value={this.state.selectedFileTypes}
                    isSearchable
                  />
                </div>
                <div data-qa="select-attachments">
                  <Dropdown
                    containerStyles={{ minWidth: 230, marginLeft: 10 }}
                    placeholder="Select Attachments"
                    options={attachmentOptions}
                    value={this.state.selectedAttachment}
                    onChange={(selectedAttachment: ReactSelectObject) => {
                      this.setState({ selectedAttachment });
                    }}
                    isSearchable
                  />
                </div>
              </div>
            </Card.Header>
            <Card.Body>{this.renderForm()}</Card.Body>
          </Card>
          <Card>
            <Card.Header className="table-header">
              Results
              <div className="download-all-button">
                {this.renderDownloadStatus()}
                {this.emailCount() > 1 ? (
                  <ButtonWithConfirmation
                    typeConfiguration={{ secondary: true }}
                    onPrimaryAction={this.handleBatchDownload}
                    disabled={
                      this.state.tableData.length < 1 ||
                      this.props.isLoadingDownloads ||
                      disableRepeatDownload
                    }
                    openDialogButtonContent="Download All Files"
                    primaryButtonContent="Download"
                    secondaryButtonContent="Cancel"
                    primaryContent={() => <p>Are you sure you wish to download all files?</p>}
                    secondaryContent={() => (
                      <div className="items-center">
                        <p>
                          Due to the volume of files, this email will be sent as {this.emailCount()}{" "}
                          separate emails.
                        </p>
                      </div>
                    )}
                    icon={DialogIcon.CAUTION}
                    getIconProps={{ large: true }}
                  />
                ) : (
                  <Button
                    onClick={this.handleBatchDownload}
                    secondary={true}
                    hasTooltip={disableRepeatDownload}
                    disabled={
                      this.state.tableData.length < 1 ||
                      this.props.isLoadingDownloads ||
                      disableRepeatDownload
                    }
                  >
                    <Tooltip message="Please select a different set of files to download." />
                    Download All Files
                  </Button>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                data={this.state.tableData}
                loading={this.props.isLoadingFiles}
                emptyState="Select a project and search to view results"
                columns={[
                  {
                    field: "storeno",
                    header: "Store No.",
                    dataQa: "store-number",
                    show: showStoreColumns,
                  },
                {
                  header: "Store Status",
                  field: "store_status",
                  formatter: (cell,row) => {
                    let projectType = row.type_id === 1 ? Constants.VALIDATION : Constants.FILE_SHARING;
                    const pill = getPillProps(cell, projectType);
                    return <Pill {...pill} />;
                  },
                    wrap: true,
                    minWidth: 160,
                    show: showStoreColumns,
                    dataQa: "store-status",
                },
                  {
                    field: "address",
                    header: "Address",
                    wrap: true,
                    minWidth: 200,
                    dataQa: "address",
                    show: showStoreColumns,
                  },
                  {
                    field: "name",
                    header: "File Name",
                    wrap: true,
                    dataQa: "file-name",
                  },
                  {
                    field: "status",
                    header: "File Status",
                    formatter: (value) => {
                      const pill = getPillProps(value.toLocaleUpperCase() as PillType);

                      return <Pill {...pill} />;
                    },
                    dataQa: "file-status",
                  },
                  {
                    field: "extension",
                    header: "File Type",
                    formatter: (value) => value.toLocaleUpperCase(),
                    dataQa: "file-extension",
                  },
                  {
                    field: "user_name",
                    header: "Uploaded By",
                    wrap: true,
                    dataQa: "uploaded-by",
                  },
                  {
                    field: "updated_at",
                    header: "Upload Date",
                    wrap: true,
                    dataQa: "upload-date",
                    formatter: (value) => {
                      const date = moment(value);

                      if (!date.isValid()) {
                        return "--";
                      }

                      return date.format("M/D/YYYY h:mm A");
                    },
                  },
                  {
                    field: "id",
                    header: "",
                    sortable: false,
                    formatter: (value: string, row: File) => {
                      return (
                        <button
                          className="download-button"
                          data-qa="download-file-button"
                          onClick={(e) => this.handleDownloadClick(e, row)}
                        >
                          <Icon src={downloadSvg} />
                        </button>
                      );
                    },
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </CardList>
      </Page>
    );
  }
}

const connector = connect(
  (state: State) => ({
    files: state.files.content?.data,
    batch_download: state.batch_download.content,
    hasError: state.batch_download.error,
    isLoadingDownloads: state.batch_download.loading,
    isLoadingFiles: state.files.loading,
  }),
  {
    createAnalyticsEvent,
    initAllFiles,
    fetchAllFiles,
    fetchZipForFilesDownload,
  }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Downloads);
